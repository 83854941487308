import React, {Component} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import Amplify from 'aws-amplify';
import { Authenticator, AmplifyTheme} from 'aws-amplify-react';
import config from './aws-exports';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/core';
import TagManager from 'react-gtm-module'
import {Helmet} from "react-helmet";

//Components
import Navbar from './components/headerComponent/navbar';
import Footer from './components/footerComponent/footer';
import ScrollToTop from './components/routerComponent/scrollRestoration';
import theme from './theme'
import Home from './components/pages/home';
import About from './components/pages/about';
import Services from './components/pages/services';
import Strategy from './components/pages/strategy';
import Contact from './components/pages/invest';
import PrivacyPolicy from './components/pages/privacyPolicy';
import GeneralDisclaimer from './components/pages/generalDisclaimer';
import RiskFactors from './components/pages/riskFactors';
import ConflictsOfInterestDisclaimer from './components/pages/conflictsOfInterestDisclaimer';
import InternetSecurity from './components/pages/internetSecurity';
import Invest from './components/pages/invest';
import Account from './components/pages/account';

//Includes
import './Assets/css/default.min.css';

Amplify.configure(config);

const brandName = "Cloud Ace";

const tagManagerArgs = {
  gtmId: 'GTM-PSLKKMP'
}

TagManager.initialize(tagManagerArgs)

const structuredJSON = {
  "@context": "http://schema.org",
  "@type": "LocalBusiness",
  "name": `${brandName}`,
  "image": "https://battlecapital.com.com/Assets/images/logo.png",
  "telephone": "7049466544",
  "email": "info@battlecapital.com",
  "address": { "@type": "PostalAddress", "streetAddress": "4402 Stuart Andrew Blvd Unit C", "addressLocality": "Charlotte", "addressRegion": "North Carolina", "addressCountry": "United States", "postalCode": "28217" },
  "openingHoursSpecification": { "@type": "OpeningHoursSpecification", "dayOfWeek": { "@type": "DayOfWeek", "name": "Mo, Tu, We, Th, Fr" }, "opens": "09:00", "closes": "17:00" },
  "url": "https://battlecapital.com/",
  "logo": "https://battlecapital.com/Assets/images/logo.png",
  "sameAs": ["https://www.facebook.com/Battle-Capital-2494791530540123/", "https://www.instagram.com/battlecapital/", "https://twitter.com/BattleCapital", "https://www.linkedin.com/company/battlecapital/", "https://www.youtube.com/channel/UC6XbMo0hN6mUmuYd_p6g01w"]
}

class App extends Component {
  state = {
    authState: {
      isLoggedIn: false
    }
  };

  handleUserSignIn = () => {
    this.setState({ authState: { isLoggedIn: true } });
  };

  render() {
    const childProps = {
      isLoggedIn: this.state.authState.isLoggedIn,
      onUserSignIn: this.handleUserSignIn
    };

    return (
      <ThemeProvider theme={theme}>
        <Router>
          <ScrollToTop>
            <CssBaseline>
              <div className="App">
              <Helmet>
                  <script className='structured-data-list' type="application/ld+json">{JSON.stringify(structuredJSON)}</script>
                  <title>{brandName}</title>
                  <meta name={brandName} content={brandName} />
                  <meta property="og:url" content="https://battlecapital.com" />
                  <meta property="og:type" content="website" />
                  <meta property="og:title" content={brandName} />
                  <meta property="og:description" content={`${brandName} | Alternative opportunities to get your money to work`} />
                  <meta property="og:image" content="https://battlecapital.com/Assets/images/logo.png" />
                  <meta name="twitter:card" content="summary" />
                  <meta name="twitter:site" content="@battlecapital" />
                  <meta name="twitter:title" content={brandName} />
                  <meta name="twitter:description" content={`${brandName} | Alternative opportunities to get your money to work`} />
                  <meta name="twitter:image" content="https://battlecapital.com/Assets/images/logo.png" />
              </Helmet>
                <Navbar brandName={brandName} loggedin={this.state.authState.isLoggedIn}/>
                <Routes childProps={childProps} loggedin={this.state.authState.isLoggedIn}/>
                <Footer brandName={brandName} />
              </div>
            </CssBaseline>
          </ScrollToTop>
        </Router>
      </ThemeProvider>
    );
  }
}

const ampTheme = {
  ...AmplifyTheme,  
}

const ProtectedRoute = ({ render: C, props: childProps, ...rest }) => (
  <Route
    {...rest}
    render={rProps =>
      childProps.isLoggedIn ? (
        <C {...rProps} {...childProps} />
      ) : (
        <Redirect
          // to={`/account?redirect=${rProps.location.pathname}${rProps.location.search}`}
          to={{
            pathname: `/account`,
            state: {from: {pathname: 'invest'}}
          }}
        />
      )
    }
  />
);

const ProppedRoute = ({ component: C, props: childProps, ...rest }) => (
  <Route {...rest} render={rProps => <C {...rProps} {...childProps} />} />
);

class AuthComponent extends Component {
  handleStateChange = state => {
    console.log(state);
    if (state === 'signedIn') {
      this.props.onUserSignIn();
    }
  };

  state = { user: null, customState: null };

  render() {

    const { from } = this.props.location.state || { from: {pathname: '/invest'}}

    if (this.props.isLoggedIn === true) {
      return (
        <Redirect to={from} />
      )
    }

    return (
    <div align="center">
        <Authenticator onStateChange={this.handleStateChange} theme={ampTheme} usernameAttributes="email" />
    </div>
    );
  }
}

const Routes = ({ childProps }) => (
  <Switch>
    <Route exact path='/' render={(props) => (<Home {...props} brandName={brandName}/>)} />
    <ProppedRoute exact path='/sign-in' component={AuthComponent} props={childProps} />
    <Route exact path='/sign-out' render={(props) => (<Home {...props} brandName={brandName}/>)} />
    <Route exact path='/about' render={(props) => (<About {...props} brandName={brandName}/>)} />
    <Route exact path='/services' render={(props) => (<Services {...props} brandName={brandName}/>)} />
    <Route exact path='/strategy' render={(props) => (<Strategy {...props} brandName={brandName}/>)} />
    <Route exact path='/contact' render={(props) => (<Contact {...props} brandName={brandName}/>)} />
    <Route exact path='/account' render={(props) => (<Account {...props} brandName={brandName}/>)} />
    <Route exact path='/privacy-policy' render={(props) => (<PrivacyPolicy {...props} brandName={brandName}/>)} />
    {/* <Route exact path='/general-disclaimer' render={(props) => (<GeneralDisclaimer {...props} brandName={brandName}/>)} />
    <Route exact path='/risk-factors' render={(props) => (<RiskFactors {...props} brandName={brandName}/>)} />
    <Route exact path='/conflicts-of-interest-disclaimer' render={(props) => (<ConflictsOfInterestDisclaimer {...props} brandName={brandName}/>)} />
    <Route exact path='/internet-security' render={(props) => (<InternetSecurity {...props} brandName={brandName}/>)} /> */}
    <ProtectedRoute exact path='/invest' render={Invest} props={childProps} />
  </Switch>
);

export default App;
