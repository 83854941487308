import React from 'react';
import {Helmet} from "react-helmet";
import Container from '@material-ui/core/Container';
import RyanImg from '../../Assets/images/ryan.jpg';
import ProcessImg from '../../Assets/images/flywheel.png';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    center: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: "300px"
    }
  }));

export default function About(props) {
    const classes = useStyles();
    return (
        <div>
            <Helmet>
                <title>{`${props.brandName} | About`}</title>
                <meta name={props.brandName} content={`${props.brandName} | About`} />
            </Helmet>      
            <Grid item xs={12}>
                <Paper elevation={3} >
                    <div id="mission" className={classes.center} valign="center">
                        <Container>
                            <h2 align="center">About Us</h2><br />
                            <h3 >Mission - Our number one priority is to make sure our portfolio companies are 
                                successful.</h3>
                            <p>
                                <strong></strong>ROI on marketing and business systems means the company is profitable and sustaining growth.
                            </p>
                        </Container>
                    </div>
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <Paper elevation={3} >
                    <div className={classes.center} valign="center">
                        <Container>
                            <h3 >Services</h3>
                            <ul>
                                <li>
                                    Marketing
                                </li>
                                <li>
                                    Business Systems
                                </li>
                                <li>
                                    It Infrastructure
                                </li>
                            </ul>
                        </Container>
                    </div>
                </Paper>
            </Grid>

            {/* <Grid item xs={12}>
                <Paper elevation={3} >
                    <div id="unique" className={classes.center} valign="center">
                        <Container>
                            <h3 align="center">How we are different</h3>
                            <h4>We are not:</h4>
                            <ul>
                                <li>Traditional money managers</li>
                                <li>Loan or equities brokers</li>
                                <li>Traditional venture capital or private equity</li>
                                <li>A typical family office of inherited wealth</li>
                            </ul>
                            <h4>We are:</h4>
                            <ul>
                                <li>Investors and we love supporting our entrepreneurial owner/partners</li>
                                <li>Problem solvers, capital allocators, and strategic decision makers</li>
                                <li>Hands on operators that support our businesses with whatever the critical needs are</li>
                            </ul>
                            <p>
                                 
                                Our skin is in the game, we take substantial portions of equity in our portfolio 
                                companies and see to it that they succeed. 
                            </p>
                        </Container>
                    </div>
                </Paper>
            </Grid> */}

            

            {/* <Container>
                <br />
                <Grid container>
                    <Grid xs={12} sm={6}>
                        <div align="center"><img src={RyanImg} style={{borderRadius: '150px'}} alt='Ryan Battle, Managing Partner'/></div>
                        <h2 align="center">Ryan Battle</h2>
                        <h3 align="center">Owner</h3>
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <p>
                            As Managing Partner, Ryan leads BC's team as the provide support for BC's clients. Ryan also offers back-office support, and leadership. 
                            Ryan earned his degree in chemical engineering from Virgina Tech and workd in the Chemical Engineering field for a decade becoming a certified PE and PMP with Dow, 
                            The Shaw Group and Midrex, serving clients in India, Russia, China, the Middle East, Latin, and North America. 
                            The critical processes and synthesis required in the building of chemical plants laid an invaluable foundation and left a desire to help businesses with essential, 
                            and often missing pieces of operations into the structure of businesses. While working with Midrex in Shanghai, China, Ryan began working as an entrepreneur and his first business was born. 
                            He served as Executive Vice President of CMIT Solutions before selling his share in 2016. He currently serves Battle Capital's clients. 
                            Ryan founded Battle Capital to build, and serve small businesses, helping them bring structure, process, and traction to their plans to maximize Growth and profit.
                        </p>
                    </Grid>
                </Grid>
                <br />
            </Container> */}
        </div>
    )
}