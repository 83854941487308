import React from 'react';
import {Helmet} from "react-helmet";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CarbideImg from '../../Assets/images/netsuite.jpg';
import QcImg from '../../Assets/images/ads.jpg';
import BdsImg from '../../Assets/images/crm.jpg';
import SkypathImg from '../../Assets/images/it.jpg';
import QcgcImg from '../../Assets/images/qcgc.jpg';
import {
    NavLink
  } from 'react-router-dom';

export default function Portfolio(props) {
    return (

        <Container>
            <Helmet>
                <title>{`${props.brandName} | Services`}</title>
                <meta name={props.brandName} content={`${props.brandName} | Services`} />
            </Helmet> 
            <h2>Services</h2>

            <p>
                Cloud Ace is a one-stop-shop for marketing, business systems and IT infrastructure. We help business utilize cutting edge technology to reduce operating expenses, increase ROI and scale.
            </p>

            <Grid container>
                <Grid align="center" xs={12} sm={4}>
                    <img src={QcImg} align="center" style={{maxHeight: '125px', borderRadius: '63px', borderStyle: 'solid', borderWidth: '1px'}} alt='Marketing'/><br /><br />
                    {/* <a className="simple-link" href="https://www.qchomebuyers.com" target="_blank"><Button variant="contained" color="secondary">View Site</Button></a> */}
                </Grid>
                <Grid xs={12} sm={8}>
                    <h3>Marketing</h3>
                    <p>
                        We help you leverage Google, Facebook, LinkedIn and Bing ads to help build awareness and connect with leads.
                    </p>
                </Grid>
            </Grid><br />

            <Grid container>
                <Grid align="center" xs={12} sm={4}>
                    <img src={CarbideImg} align="center" style={{maxHeight: '125px', borderRadius: '63px', borderStyle: 'solid', borderWidth: '1px'}} alt='Netsuite'/><br /><br />
                    {/* <a className="simple-link" href="https://www.facebook.com/Carbide-Tix-2259309821007868" target="_blank"><Button variant="contained" color="secondary">View Site</Button></a> */}
                </Grid>
                <Grid xs={12} sm={8}>
                    <h3>NetSuite</h3>
                    <p>
                        NetSuite is an ERP that allows businesses to scale through a number of navite and 3rd party modules. Customizing an instance of NetSuite will allow compnies to centralize data and create processes that reduce the duplication of efforts.
                    </p>
                </Grid>
            </Grid><br />

            <Grid container>
                <Grid align="center" xs={12} sm={4}>
                    <img src={BdsImg} align="center" style={{maxHeight: '125px', borderRadius: '63px', borderStyle: 'solid', borderWidth: '1px'}} alt='CRM'/><br /><br />
                    {/* <a className="simple-link" href="https://www.battledesignstudio.com" target="_blank"><Button variant="contained" color="secondary">View Site</Button></a> */}
                </Grid>
                <Grid xs={12} sm={8}>
                    <h3>CRM</h3>
                    <p>
                        Depending on requirements, we can set up SalesForce or HubSpot CRM. These robust platforms excell at creating wonderful customer experinces through intuitive employee UX.
                    </p>
                </Grid>
            </Grid><br />

            <Grid container>
                <Grid align="center" xs={12} sm={4}>
                    <img src={SkypathImg} align="center" style={{maxHeight: '125px', borderRadius: '63px', borderStyle: 'solid', borderWidth: '1px'}} alt='IT Infrastructure'/><br /><br />
                    {/* <a className="simple-link" href="https://www.skypathrealty.com" target="_blank"><Button variant="contained" color="secondary">View Site</Button></a> */}
                </Grid>
                <Grid xs={12} sm={8}>
                    <h3>IT Infrastructure</h3>
                    <p>
                        We offer a broad range of support for IT solutions.
                        <ul>
                            <li>
                                Email and Internal Chat
                            </li>
                            <li>
                                Phone and IVR
                            </li>
                            <li>
                                Active Directory
                            </li>
                            <li>
                                Firewalls
                            </li>
                            <li>
                                Servers
                            </li>
                            <li>
                                Redundancy and Recovery
                            </li>
                        </ul>
                    </p>
                </Grid>
            </Grid><br />

            {/* <Grid container>
                <Grid align="center" xs={12} sm={4}>
                    <img src={QcgcImg} align="center" style={{maxHeight: '125px', borderRadius: '63px', borderStyle: 'solid', borderWidth: '1px'}} alt='Battle Design Studio Logo'/><br /><br />
                    <a className="simple-link" href="https://www.qcgeneralcontractors.com" target="_blank"><Button variant="contained" color="secondary">View Site</Button></a>
                </Grid>
                <Grid xs={12} sm={8}>
                    <h3>QC General Contractors</h3>
                    <p>
                        QC General Contractors provides contracting service for new construction, additions and renovations in the Charlotte, NC area.
                    </p>
                </Grid>
            </Grid> */}
            
            <br /><br />      
            
        </Container>
    )
}