/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:ef98e575-07b0-4ac9-944d-fbd77c2f626a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_p4HKVUBmr",
    "aws_user_pools_web_client_id": "s9r663jb33kr8maq1beet29a3",
    "oauth": {
        "domain": "battlecapital-master.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.battlecapital.com/sign-in/",
        "redirectSignOut": "https://www.battlecapital.com/sign-out/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
