import React from 'react';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {
    NavLink
  } from 'react-router-dom';
import SupportImg from '../../Assets/images/support.jpg';
import ServicesImg from '../../Assets/images/services.jpg';
import GuidanceImg from '../../Assets/images/guidance.jpg';
import {Helmet} from "react-helmet";


const useStyles = makeStyles((theme) => ({

    li: {
        listStyleType: 'none',
    },
    whiteOnDark: {
        backgroundColor: '#496074',
        color: '#fff',
        borderRadius: 0,
        minHeight: '100%',
    },
    center: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: "300px"
    },
    h2: {
        marginBlockStart: '0em',
    },
    card: {
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
    },
    update: {
        backgroundColor: 'green',
        color: 'white',
        padding: '20px',
    }
  }));
export default function HomePage(props) {
    const classes = useStyles();
        return (
            <div>
                <Helmet>
                    <title>{`${props.brandName} | Home`}</title>
                    <meta name={props.brandName} content="Cloud Ace's wealth of experience and relational networks provide clear direction for new businesses an leaders. Cloud Ace focuses on developing marketing strategies and business systems" />
                </Helmet> 
                <div id="hero" align="center"  className="center">
                    <div>
                        <h1>Your marketing and<br />business systems partner</h1>
                        <NavLink to="/services" className="simple-link"><Button variant="contained" color="secondary">Learn More</Button></NavLink>
                    </div>
                </div>

                <Grid item xs={12}>
                        <Paper elevation={3} >
                            <div className={classes.center} valign="center">
                                <Container>
                                    <p>
                                        <strong>What’s Next?</strong><br />
                                        Continue to ride the wave and allocate resources to diversify, 
                                        compliment, and grow the portfolio to generate as much responsible value as possible. 
                                        This is what we love to do.
                                    </p>
                                </Container>
                            </div>
                        </Paper>
                    </Grid>

                <div>
                    <Grid item xs={12}>
                        <Paper elevation={3} className={classes.whiteOnDark}>
                            <div id="problem" className={classes.center} valign="center">
                                <Container>
                                    <h2 className={classes.h2}>The Problem</h2>
                                    <p>
                                        Lets face it, building a company is challenging, especially in the first year. Businesses are faced with limited resources, 
                                        little or no support to establish systems, optimize operations, manage clients, hire the right team, and develop strategy. 
                                        Scores of businesses die before their operations can catch up with the vision.
                                    </p>
                                    <ul>
                                        <li>
                                            137,000 businesses are formed every day - this is 5 million per year
                                        </li>
                                        <li>
                                            90% will fail
                                        </li>
                                    </ul>
                                    <p>
                                        The top reason for failure are poor planning, operational deficiencies, ineffective strategy, and inexperienced and underdeveloped leadership.
                                    </p>
                                </Container>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                            <Container className="section">
                                <br />
                                <h2 className={classes.h2}>Our Solution</h2>
                                <p>
                                    That's when we come in - we join with businesses on the front-lines.
                                    {/* bringing established back-office support and critically needed operations, but most importantly, 
                                    we bring our hard-won experience and use it to guide businesses in the trenches through those first critical battles 
                                    to accelerate growth rates and enable sustainability */}
                                </p>
                                <div align="center">
                                    <Grid container>
                                        <Grid xs={12} sm={4}>
                                            <img src={SupportImg} align="center" style={{borderRadius: '63px'}} alt='Team work'/> Support
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <img src={ServicesImg} align="center" style={{borderRadius: '63px'}} alt='Network of services'/> Services
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <img src={GuidanceImg} align="center" style={{borderRadius: '63px'}} alt='Consulting'/> Guidance
                                        </Grid>
                                    </Grid><br />
                                    {/* <Grid container>
                                        <Grid xs={12} sm={4}>
                                            <img src={SupportImg} align="center" style={{borderRadius: '63px'}} alt='Team work'/> Support
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <img src={ServicesImg} align="center" style={{borderRadius: '63px'}} alt='Network of services'/> Services
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <img src={GuidanceImg} align="center" style={{borderRadius: '63px'}} alt='Consulting'/> Guidance
                                        </Grid>
                                    </Grid><br />
                                    <Grid container>
                                        <Grid xs={12} sm={4}>
                                            <img src={SupportImg} align="center" style={{borderRadius: '63px'}} alt='Team work'/> Support
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <img src={ServicesImg} align="center" style={{borderRadius: '63px'}} alt='Network of services'/> Services
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <img src={GuidanceImg} align="center" style={{borderRadius: '63px'}} alt='Consulting'/> Guidance
                                        </Grid>
                                    </Grid> */}
                                </div>
                                <br />
                            </Container>
                    </Grid>
                </div>
            </div>
        )
    }