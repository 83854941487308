import React from 'react';
import Container from '@material-ui/core/Container';
// import Grid from '@material-ui/core/Grid';
import {Helmet} from "react-helmet";
import HubspotForm from 'react-hubspot-form'

export default function Invest() {

    // componentDidMount() {
    //     const script = document.createElement('script');
    //     script.src = 'https://js.hsforms.net/forms/v2.js';
    //     document.body.appendChild(script);
        
    //     script.addEventListener('load', () => {
    //         if(window.hbspt) {
    //             window.hbspt.forms.create({
    //             portalId: "7272822",
    //             formId: "10d49264-f02c-4450-8b39-9b1010ed8463",
    //             target: '#hubspotForm'
    //         })
    //         }
    //     });
    // }

    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = 'https://js.hsforms.net/forms/v2.js';
    //     document.body.appendChild(script);
        
    //     script.addEventListener('load', () => {
    //         if(window.hbspt) {
    //             window.hbspt.forms.create({
    //             portalId: "7272822",
    //             formId: "10d49264-f02c-4450-8b39-9b1010ed8463",
    //             target: '#hubspotForm'
    //         })
    //         }
    //     });
    // });

    return (
        <div className="wrap">
            <Container>
            <Helmet>
                {/* <title>{`${props.brandName} | Contact`}</title>
                <meta name={props.brandName} content={`${props.brandName} | Contact`} /> */}
            </Helmet>
                <div>
                    <h1>Contact Us</h1>
                    <HubspotForm
                    portalId='7528148'
                    formId='a335188c-c2ad-471d-a863-9c3307b6e20f'
                    onSubmit={() => console.log('Submit!')}
                    onReady={(form) => console.log('Form ready!')}
                    loading={<div>Loading...</div>}
                    />
                </div>
            </Container>
            </div>
        
    )
}