import React from 'react';
import Button from '@material-ui/core/Button';
import {Auth} from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import {
    NavLink
  } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
    facebook: {
        backgroundColor: '#1877f2',
        color: '#fff',
        '&:hover' : {
            backgroundColor: 'rgba(24, 119, 242, 0.75)'},
    },
    google: {
        backgroundColor: '#cc0000',
        color: '#fff',
        '&:hover' : {
            backgroundColor: 'rgba(204, 0, 0, 0.75)'}, 
    },
  }));

export default function Account(props) {
    const classes = useStyles();

    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

        return (
            <div align="center">
                <Container>
                <Helmet>
                    <title>{`${props.brandName} | Sign In`}</title>
                    <meta name={props.brandName} content={`${props.brandName} | Sign In`} />
                </Helmet> 
                <h1>Join the Battle Capital Community</h1>
                
                    <Grid container spacing={0} alignItems="stretch" direction="row" justify="flex-start">
                        <Grid item xs={12} sm={2} />
                        <Grid item xs={2} sm={1} align="center">
                        <Checkbox
                            checked={checked}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'Accreditation checkbox' }}
                        />
                        </Grid>
                        <Grid item xs={10} sm={8} align="left">
                        <div>I meet the {<NavLink to='/general-disclaimer'>Accreditation Terms</NavLink>}, agree to the {<NavLink to='/terms-of-use'>Privacy Policy</NavLink>} and {<NavLink to='/terms-of-use'>Terms of Use</NavLink>},<br />including its provisions concerning Confidentiality and Non-Circumvention</div>
                        </Grid>
                    </Grid>
                
                <br />
                <h4>Join with one click</h4>
                <Button disabled={!checked} variant="contained" className={classes.facebook} onClick={() => Auth.federatedSignIn()}>&nbsp;<FontAwesomeIcon icon={faFacebook} size="2x" />&nbsp;Sign in with Facebook&nbsp;</Button>
                <br /><br />
                <Button disabled={!checked} variant="contained" className={classes.google} onClick={() => Auth.federatedSignIn()}>&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faGoogle} size="2x" />&nbsp;Sign in with Google&nbsp;&nbsp;&nbsp;</Button>
                <p>Or</p>
                <Button disabled={!checked} variant="contained" color="secondary" onClick={() => Auth.federatedSignIn()}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<EmailIcon style={{ fontSize: 28 }}/>&nbsp;Sign in with Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>
                <br />&nbsp;
                </Container>
            </div>
        )

}

