import React from 'react';
import {Helmet} from "react-helmet";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import StrategyImg from '../../Assets/images/strategyLg.jpg';
export default function Strategy(props) {
    return (
        <div>
            <Container>
            <Helmet>
                <title>{`${props.brandName} | Strategy`}</title>
                <meta name={props.brandName} content={`${props.brandName} | Strategy`} />
            </Helmet> 
                {/* <Grid container>
                    <Grid xs={12} sm={6}>
                        <img src={StrategyImg} align="center" alt='Strategy'/>
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <h2 align="center" >Marketing Strategy</h2>
                        <p>
                            We build a custom marketing strategy based on data and A/B testing. As your strategy matures, we expect to see positive corelation in ROI
                        </p>
                    </Grid>
                    
                </Grid><br /> */}

                <Grid container>
                    
                    <Grid xs={12} sm={6} id="marketingstrategy">
                        <Container>
                            <h2 align="center" >Marketing Strategy</h2>
                            <p>
                            We build a custom marketing strategy based on data and A/B testing. As your strategy matures, we expect to see positive corelation in ROI.
                            </p>
                        </Container>
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <ul>
                            <li>NetSuite</li>
                            <li>SalesForce</li>
                            <li>HubSpot</li>
                            <li>Google Workspace</li>
                        </ul>
                    </Grid>
                </Grid><br />

                <Grid container></Grid>

                <Grid container>
                    
                    <Grid xs={12} sm={6} id="evaluation">
                        <Container>
                            <h2 align="center" >Business Systems Strategy</h2>
                            <p>
                                Based on requirements and budget we have a number of software options we can implement to support you business goals.
                            </p>
                        </Container>
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <ul>
                            <li>NetSuite</li>
                            <li>SalesForce</li>
                            <li>HubSpot</li>
                            <li>Google Workspace</li>
                        </ul>
                    </Grid>
                </Grid><br />

                <Grid container>
                    
                    <Grid xs={12} sm={6} id="vetting">
                        <Container>
                            <h2 align="center" >IT Strategy</h2>
                            <p>
                                Our primary goals with IT infrastructure is security, redundancy and ease of administration.
                            </p>
                        </Container>
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <ul>
                            <li>Watchguard</li>
                            <li>AWS EC2 and S3 Glacier</li>
                            <li>Cloudberry</li>
                        </ul>
                    </Grid>
                </Grid>
            </Container>
            <br />
        </div>
    )
}