import React from 'react';
import {Grid, Typography, Container, List, ListItem, ListItemText, Fab} from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import ExploreIcon from '@material-ui/icons/Explore';
import WorkIcon from '@material-ui/icons/Work';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import { makeStyles } from '@material-ui/core/styles';
import {
    NavLink
  } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    facebook: {
        backgroundColor: '#1877f2',
        color: '#fff',
        marginRight: '10px',
        '&:hover' : {
            backgroundColor: 'rgba(24, 119, 242, 0.75)'},
    },
    twitter: {
        backgroundColor: '#1da1f2',
        color: '#fff',
        marginRight: '10px',
        '&:hover' : {
            backgroundColor: 'rgba(29, 161, 242, 0.75)'},
    },
    insta: {
        backgroundColor: '#8a3ab9',
        color: '#fff',
        marginRight: '10px',
        '&:hover' : {
            backgroundColor: 'rgba(138, 58, 185, 0.75)'},
    },
    linkedin: {
        backgroundColor: '#0a66c2',
        color: '#fff',
        marginRight: '10px',
        '&:hover' : {
            backgroundColor: 'rgba(10, 102, 194, 0.75)'},
    },
    youtube: {
        backgroundColor: '#cc0000',
        color: '#fff',
        '&:hover' : {
            backgroundColor: 'rgba(204, 0, 0, 0.75)'}, 
    },
    footer: {
        background: theme.palette.primary.main,
        color: 'white'
    }
  }));

export default function Footer(props) {
  const classes = useStyles();
  const year = new Date().getFullYear();

  return (
    <footer className={classes.footer}>
      <Container className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={2}>
            <div>
                <h3 align="center">Quick Links</h3>
            <List>
            {[
                {
                  title: 'Home',
                  link: '/',
                  icon: <HomeIcon />},
              {
                  title: 'About',
                  link: '/about',
                  icon: <InfoIcon />},
               {
                  title: 'Services',
                  link: '/portfolio',
                  icon: <WorkIcon />},
              {
                  title: 'Strategy',
                  link: '/strategy',
                  icon: <ExploreIcon />},

              {
                title: 'Contact',
                link: '/contact',
                icon: <MailIcon/>}].map((text, index) => (
              <NavLink to={text.link} key={text.title} className={classes.link}>
                  <ListItem button>
                      {/* <ListItemIcon className={classes.footer}>{text.icon}</ListItemIcon> */}
                      <ListItemText primary={text.title} />
                  </ListItem>
              </NavLink>
            ))}
          </List>
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <div>
                <h3 align="center">About Us</h3>
                <p>
                {props.brandName} brings established marketing and business systems support as well as our hard-won experience to guide businesses through the critical challenges 
                of change and accelerate growth rates to enable sustainable scalability
                </p>
            </div>
          </Grid>
          <Grid item xs={12} lg={4}>
            <div>
                <h3 align="center">Follow Us</h3>
                <div align="center">
                <span>
                    <a href="https://www.facebook.com/cloudacegroup/" target="_blank" rel="noreferrer">
                    <Fab className={classes.facebook} aria-label="add">
                          <FacebookIcon />
                    </Fab>
                    </a>
                    <a href="https://twitter.com/BattleCapital" target="_blank" rel="noreferrer">
                    <Fab className={classes.twitter} aria-label="add">
                          <TwitterIcon />
                    </Fab>
                    </a>
                    <a href="https://www.instagram.com/battlecapital/" target="_blank" rel="noreferrer">
                    <Fab className={classes.insta} aria-label="add">
                          <InstagramIcon />
                    </Fab>
                    </a>
                    <a href="https://www.linkedin.com/company/cloud-ace-group/" target="_blank" rel="noreferrer">
                    <Fab className={classes.linkedin} aria-label="add">
                          <LinkedInIcon />
                    </Fab>
                    </a>
                    <a href="https://www.youtube.com/channel/UC6XbMo0hN6mUmuYd_p6g01w" target="_blank" rel="noreferrer">
                    <Fab className={classes.youtube} aria-label="add">
                          <YouTubeIcon />
                    </Fab>
                    </a>
                </span>
                </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div align="center">
                  <span>
                    © {year} {props.brandName}, LLC.&nbsp;All Rights Reserved. <NavLink to="/terms-of-use">Privacy Policy</NavLink>
                    {/* <NavLink to="/general-disclaimer">General Disclaimer</NavLink> |&nbsp;
                    <NavLink to="/risk-factors">Risk Factors</NavLink> |&nbsp;
                    <NavLink to="/conflicts-of-interest-disclaimer">Conflicts of Interest Disclaimer</NavLink> |&nbsp;
                    <NavLink to="/internet-security">Internet Security</NavLink> */}
                  </span><br /><br />
            </div>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}