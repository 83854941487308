import React from 'react';
import {Helmet} from "react-helmet";
import {Container} from '@material-ui/core';

export default function TermsOfUse(props) {
  return (
      <div>
        <Container>
          <Helmet>
              <title>{`${props.brandName} | Terms of Use`}</title>
              <meta name={props.brandName} content={`${props.brandName} | Terms of Use`} />
          </Helmet> 
          <div>
            <h1><strong>CLOUD ACE: PRIVACY POLICY</strong></h1>
              <p>This Privacy Policy explains how Cloud Ace Software, Inc and their respective subsidiaries (“Cloud Ace”) collect, use and share your information. This Privacy Policy applies to information we collect when you use our websites, mobile applications and other online products and services (collectively, the “Services”) or when you otherwise interact with us, including when you attend events hosted or attended by Cloud Ace, contact us for customer support or visit our premises, facilities or offices (collectively, our “Facilities”).&nbsp; This Privacy Policy does not apply to the information processed by Cloud Ace about its employees or job applicants, which is governed by a separate personnel privacy notice; or to information processed by Cloud Ace on behalf of its customers via the Services.</p>
<p>We may change this Privacy Policy from time to time.&nbsp; If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide you with additional notice (such as by adding a statement to our website homepages or sending you an email notification) or otherwise seek additional consent in accordance with applicable law.&nbsp; We encourage you to review the Privacy Policy whenever you access the Services to stay informed about our information practices and the ways you can help protect your privacy.</p>
<h1>Collection of Information</h1>
<h2>Information You Provide to Us</h2>
<p>We collect information you provide directly to us.&nbsp; For example, we collect information when you create an account (including a product user account), participate in interactive features of the Services, fill out a form, participate in a contest, promotion or survey, register for or attend an event or course, make a purchase, request customer support, or otherwise communicate with us.&nbsp; The types of information you may choose to provide include your name, email address, postal address, phone number, fax number, payment information, gender, and information about your company and current position.</p>
<h2>We Collect Automatically When You Use the Services</h2>
<p>When you access or use our Services, we automatically collect some or all of the following information about you:</p>
<ul>
<li>Log Information:&nbsp; We log information about your use of the Services, including the type of browser you use, access times, pages viewed, your IP address and the page you visited before navigating to our Services.</li>
<li>Device Information:&nbsp;&nbsp;We collect information about the computer or mobile device you use to access our Services, including the hardware model, operating system and version, unique device identifiers and mobile network information.</li>
<li>Product Usage Information: When you use our enterprise products and services on behalf of your organization, we collect information about your device and your use of those products and services, including the device IP address, login credentials, time zone, error messages, product interactions and configurations and other similar usage information.</li>
<li>Information Collected by Cookies and Other Tracking Technologies:&nbsp; We use various technologies to collect information, and this includes sending cookies to your computer or mobile device.</li>
<li>Inferences: We draw inferences about you from information we collect, such as your interest in particular products or services based on the pages you visit on our website.</li>
</ul>
<h2>Information We Collect from Other Sources</h2>
<p>We also obtain information from other sources and combine that with information we collect about you.&nbsp; For example, if you connect an Cloud Ace account with your social media account, we may have access to certain information from the company that owns or operates the social media account in accordance with the authorization procedures determined by such social media company.&nbsp; Cloud Ace may also receive information about you, the company you may work for and your approximate location from data enrichment service providers, advertising and marketing partners, public databases, and social media platforms.&nbsp; Additionally, Cloud Ace receives information about you from other third parties who you have authorized to share your information, or who collect information in connection with co-branded offerings.</p>
<h2>Information We Collect at Our Facilities</h2>
<p>If you visit our Facilities, the video security cameras we use to help protect our employees, visitors, and property may record digital images of you, and we may collect information from you to enable us to identify you for security purposes.</p>
<h2>Use of Information</h2>
<p>We use information about you for the following purposes:</p>
<ul>
<li>Provide, maintain and improve our Services;</li>
<li>Provide and deliver the products and services you request, process transactions and send you related information, including confirmations and invoices;</li>
<li>Send you technical notices, updates, security alerts and support and administrative messages;</li>
<li>Respond to your comments, questions and requests, and provide customer service;</li>
<li>Communicate with you about products, services, offers, promotions, rewards, and events offered by Cloud Ace and others, and provide news and information we think will be of interest to you;</li>
<li>Monitor and analyze trends, usage and activities in connection with our Services;</li>
<li>Personalize and improve the Services and provide advertisements, content or features that match user profiles or interests;</li>
<li>Help prevent unauthorized access to our Facilities, secure property and information, protect the health and safety of our personnel and visitors, and detect and prevent criminal behavior or misconduct;</li>
<li>Comply with our legal obligations; and</li>
<li>Process and deliver contest entries and rewards.</li>
</ul>
<h2>Sharing of Information</h2>
<p>We share information about you as follows or as otherwise described in this Privacy Policy.&nbsp; See “Your Rights to Information about Sharing” below for more information about this sharing.:</p>
<ul>
<li>With your consent or at your direction, including with third parties who participate in an Cloud Ace joint marketing campaign, seminar, marketplace product offering or other joint product, service or event, and/or with distributors and resellers of Cloud Ace products and services or with sellers of products and services complementary to Cloud Ace products and services, for their sales and marketing purposes.</li>
<li>With vendors, consultants and other service providers to perform services for us, including data enrichments service providers and analytics service providers.</li>
<li>If required by applicable law, regulation or legal process, including in connection with lawful requests by law enforcement, national security, or other public authorities;</li>
<li>To protect the rights, property and safety of Cloud Ace or others;</li>
<li>With enterprise customers on whose behalf you use our Services, e.g., as an employee;</li>
<li>In connection with the merger or sale of all or a portion of our business to another company; and</li>
</ul>
<p>We also share aggregated or de-identified information, which cannot reasonably be used to identify you.</p>
<h2>Advertising and Analytics Services Provided by Others</h2>
<p>We allow others to serve advertisements on our behalf across the Internet and to provide analytics services on our behalf.&nbsp; These entities use cookies, web beacons and other technologies to collect information about your use of the Services and other websites, including your IP address, web browser, pages viewed, time spent on pages, links clicked and conversion information.&nbsp; This information is used by Cloud Ace and these entities to analyze and track data, determine the popularity of certain content, deliver advertising and content targeted to your interests on our Services and other websites, and better understand your online activity.&nbsp; This Privacy Policy does not apply to, and we are not responsible for, third-party cookies, web beacons, or other tracking technologies.</p>
<h2>Transfer of Information</h2>
<p>SCS processes information in the United States and other countries<em>.&nbsp;&nbsp;</em>Accordingly, we and our service providers may transfer your information to, or store or access it in, jurisdictions that may not provide equivalent levels of data protection as your home jurisdiction.&nbsp; We will take steps to ensure that your personal data receives an adequate level of protection in the jurisdictions in which we process it.&nbsp; For instance, data transferred from the European Economic Area (“EEA”) to Cloud Ace Software, Inc and its subsidiaries in the United States will be processed pursuant to the EU-U.S. Privacy Shield (see below for more details).&nbsp; In other cases, we will use European Commission-approved Standard Contractual Clauses to ensure an adequate level of protection for data originating from the EEA. &nbsp;Copies of the Standard Contractual Clauses we use to facilitate the transfer of data outside the EEA are available&nbsp;<a href="http://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32004D0915">here</a>&nbsp;and&nbsp;<a href="http://eur-lex.europa.eu/legal-content/en/TXT/?uri=CELEX%3A32010D0087">here</a>.</p>
<h2>Residents in the European Economic Area</h2>
<p>If we collect your personal data while you are located in the EEA, you have certain rights and protections under the law regarding the processing of this personal data.&nbsp; Please see the sections below for more details.</p>
<h2>Legal Basis for Processing</h2>
<p>When we process this personal data, we will only do so in the following situations:</p>
<ul>
<li>We need to use your personal data to perform our responsibilities under our contract with you (e.g., processing payments for and providing our services you have requested).</li>
<li>We have a legitimate interest in processing your personal data.&nbsp; For example, we process the personal data we collect from you to send you marketing communications, to communicate with you about changes to our Services, to enhance the safety and security of our Facilities, and to provide, secure, and improve our Services.</li>
<li>If we request your consent to a specific processing activity, and you provide your consent in the manner indicated.</li>
<li>In some cases, processing will be necessary for compliance with a legal obligation, such as response to legal process requests.</li>
</ul>
<h2>Data Subject Requests for EU and EEA Residents</h2>
<p>If you are in the EEA, you have the right to access personal data we hold about you and to ask that your personal data be corrected.&nbsp; In certain cases, you may also have the right to request that we erase data we are processing or to restrict or object to certain of our processing activities.&nbsp; You may also have the right to request that we provide the personal data you have provided us in a portable form for transmission to another controller’s service.&nbsp; If you would like to exercise any of these rights, you may contact us as indicated in the “Contact Us” section below.</p>
<h2>Questions or Complaints</h2>
<p>If you are in the EEA and have a concern about our processing of personal data that we are not able to resolve, you have the right to lodge a complaint with the data protection authority where you reside. For contact details of your local Data Protection Authority, please see:&nbsp;<a href="https://edpb.europa.eu/about-edpb/board/members_en">https://edpb.europa.eu/about-edpb/board/members_en</a></p>
<h2>EU-U.S. Privacy Shield Framework</h2>
<p>SCS Software, Inc and its US subsidiaries adhere in their processing of personal data received from the EEA (“EU Data”) to the EU-U.S. Privacy Shield Framework Principles established by the U.S. Department of Commerce and the European Commission (the “Principles”).&nbsp; For more information about the Principles, please visit the Department of Commerce’s&nbsp;<a href="https://www.privacyshield.gov/">Privacy Shield website</a>.</p>
<p>Please direct any inquiries or complaints regarding our compliance with the Principles to the point of contact listed in the “Contact Us” section below.&nbsp; If Cloud Ace does not resolve your complaint, you may submit your complaint free of charge for resolution to&nbsp;JAMS, Cloud Ace’s designated independent Privacy Shield dispute resolution provider, at&nbsp;<a href="https://www.jamsadr.com/eu-us-privacy-shield">https://www.jamsadr.com/eu-us-privacy-shield</a>. &nbsp;Under certain conditions specified by the Principles, you may also be able to invoke binding arbitration to resolve your complaint.&nbsp; Cloud Ace is subject to the investigatory and enforcement powers of the Federal Trade Commission.&nbsp; If Cloud Ace shares EU Data with a third-party service provider that processes the data solely on Cloud Ace’s behalf, then Cloud Ace may be held liable for that third party’s processing of EU Data in violation of the Principles, unless Cloud Ace can prove that it is not responsible for the event giving rise to the damage.</p>
<h2>California Residents</h2>
<p>Under the California Consumer Privacy Act of 2018 (“CCPA”), you have specific rights regarding your personal information if you are a California resident (“Consumer”) as further described in this section.</p>
<h2>Personal Information We Collect, Disclose, and Sell</h2>
<p>Subject to certain limitations and exceptions, you have the right to know what personal information is being collected, disclosed, used, or sold by Cloud Ace.&nbsp; We may collect certain types of personal information from you as described in the Collection of Information section above in connection with services and marketing that we provide. Specifically, within the last twelve (12) months, we have collected the following categories of personal information from Consumers:</p>
<ul>
<li>Identifiers such as your real name, alias, postal address, online identifier, Internet Protocol address, and email address.</li>
<li>Demographic information, such as gender and age.</li>
<li>Commercial information such as records of products or services purchased or considered</li>
<li>Internet or other electronic network activity information, such as search history and interaction with our websites, applications, or advertisements.</li>
<li>Geolocation data, such as the physical location of you or your device.</li>
<li>Electronic, visual, or other similar information, such as photos, videos, or webcasts from events.</li>
<li>Professional or employment related information, such as current or past employment status.</li>
<li>Inferences drawn from the personal information above reflecting your preferences, characteristics, and behavior.</li>
</ul>
<p>To learn more about the sources from which we collect those categories of personal information, the business purposes for which we collect them, and the categories of third parties with whom share them, see the Collection of Information, Use of Information, and Sharing of Information sections above.&nbsp; We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated or incompatible purposes without providing you notice.</p>
<h2>Your Consumer Rights Under the CCPA</h2>
<p>Subject to certain limitations and exceptions, you have the right to request that Cloud Ace disclose information to you regarding our collection and use of your personal information at present and in the past twelve (12) months, including the categories and specific pieces of personal information we collected, used, sold or disclosed. You may have the right to request that we delete your personal information that we have collected or maintain, subject to certain exceptions.</p>
<h2>How to Submit a Request for Access or Deletion</h2>
<p>If you would like more information or would like to submit a privacy-related request described above, you may do so by:</p>
<ul>
<li>Emailing Us at <a href="mailto:info@scscloud.com">info@scscloud.com</a> describing the general nature of your request; or</li>
<li>Calling Us from North America at this toll-free phone number: 844-333-2568</li>
</ul>
<h2>Verification of Request</h2>
<p>Once you submit a request using one of the methods above, we will verify your personal identity based on the information we receive from you.&nbsp; Typically, we may ask business contacts for full name, email address, physical address or phone number.&nbsp; If you are contacting us from an email address not on file, we may ask for at least one other data point we already hold (such as an Cloud Ace event you attended).&nbsp; Depending on data we have on file or other circumstances, we may ask for more or different information. &nbsp;You can also designate an authorized agent to exercise these rights on your behalf, but we will require proof that the person is authorized to act on your behalf and may also still ask you to verify your identity with us directly. You may also make a privacy request on behalf of your minor child.</p>
<p>We cannot respond to your request or provide you with personal information if we cannot verify your identity or that you have the authority to request the personal information. We will use personal information provided in a request only to verify the requestor’s identity or authority to make the request.</p>
<h2>Non-Discrimination</h2>
<p>We will not discriminate against you for exercising your rights under the CCPA.</p>
<h2>Data Retention</h2>
<p>We store this personal data for as long as is necessary for the purposes for which we originally collected it.&nbsp; We may retain certain information for legitimate business purposes or as required by law.</p>
<h2>Your Choices</h2>
<h2>Access Rights</h2>
<p>If you are a resident of a country that provides you with the right to request a copy of the personal data Cloud Ace holds about you and/or to correct any inaccuracies within such data, then you may address such requests to the contact information provided in the “Contact Us” section below.&nbsp; Cloud Ace will use reasonable efforts to supply, correct or delete information that we hold about you or to advise you if we are not required by applicable laws to comply with such requests.</p>
<h2>Account Information</h2>
<p>If you have registered for an account via our Services, you may update, correct or delete your account information by logging into your online account.</p>
<h2>Location Information</h2>
<p>Some of our mobile applications collect precise geolocation information from your mobile device.&nbsp; Prior to collecting such information, you will be asked to consent to the application’s collection of this information.&nbsp; If you initially consent to our collection of location information, you may be able to subsequently stop the collection of this information through your device operating system settings.&nbsp; You may also stop our collection of location information by following the standard uninstall process to remove our mobile applications from your device.</p>
<h2>Promotional Communications</h2>
<p>You may opt out of receiving promotional communications from Cloud Ace by following the instructions provided in those communications.&nbsp; Please note that if you opt out of receiving promotional communications, we may still send you transactional or relationship messages, such as those about your account or our ongoing business relations.</p>
<h2>Your Rights to Information about Sharing</h2>
<p>Residents of California (under California’s “Shine the Light” law (Civil Code §1798.83)) and residents of certain international countries may request certain details, pursuant to applicable laws, about how their information is shared with third parties for direct marketing purposes.&nbsp; If you are a resident of one of these jurisdictions, and would like to make such a request, please contact us by one of the methods indicated below.</p>
<h2>Contact Us</h2>
<p>If you have any questions about this Privacy Policy, requests regarding your personal data or complaints, or need to request a copy of this Privacy Policy in a different format to accommodate a disability, please contact us at:</p>
{/* <p>Cloud Ace<br>
Attn: Legal Department<br>
2840 West Bay Drive<br>
Suite 125<br>
Belleair Bluffs, FL 33770</p> */}

<p><a href="mailto:info@cloudacegroup.com">info@cloudacegroup.com</a></p></div>
        </Container>
      </div>
  )
}