import { createMuiTheme } from '@material-ui/core';

//Global CSS
const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#000000'
      },
      secondary: {
        main: '#3ca267'
      },
    },
    button: {
        borderRadius: '50px'
    },
    typography: {
        fontFamily: '"Poppins", sans-serif',
        h1: {
            fontWeight: 600
        },
        h2: {
            fontWeight: 600
        },
        h3: {
            fontWeight: 600
        },
        h4: {
            fontWeight: 600
        },
        h5: {
            fontWeight: 600
        },
        h6: {
            fontWeight: 600
        }
    },
    Fab: {
        color: {
            facebook: {
                backgroundColor: 'red'
            }
        }
    },
  });

  export default theme;